import * as React from "react"
import Layout from "../components/Layout"
import styled from "styled-components"
import { Link } from "gatsby"
import Seo from "../components/SEO"

const Error404 = styled.section`
  background-color: var(--background);
  color: #fff;
`

const Title = styled.h1`
  margin-top: 0;

  @media (min-width: 768px) {
    grid-column: 1 / 2;
  }
`

const SubContent = styled.div`
  p {
    margin-bottom: 2.125rem;
  }
  @media (min-width: 768px) {
    grid-column: 2 / 3;
  }
`

const SubTitle = styled.h2`
  @media (min-width: 768px) {
    margin-top: 0;
  }
`

const error = () => {
  return (
    <>
      <Seo title="Błąd" />
      <Layout>
        <Error404 className="section-padding">
          <Title>Przepraszamy.</Title>
          <SubContent>
            <SubTitle>Ta strona juz nie istnieje</SubTitle>
            <Link className="btn" to="/">
              Wróć do głównej strony
            </Link>
          </SubContent>
        </Error404>
      </Layout>
    </>
  )
}

export default error
